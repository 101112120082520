export function setupDetailListToggle() {
  const detailList = document.querySelector('[data-tour__program__detail__list]');
  if (detailList) {
    const toggleButton = document.querySelector('[data-tour-detail-expand-button]');
    toggleButton.addEventListener('click', function () {
      const isExpanded = detailList.getAttribute('data-is-expanded') === 'true';
      detailList.setAttribute('data-is-expanded', isExpanded ? 'false' : 'true');
      toggleButton.querySelector('[data-tour-detail-expand-button-title]').innerText =
        isExpanded ? toggleButton.getAttribute('data-content-expand') : toggleButton.getAttribute('data-content-hide');
      if (isExpanded) {
        const scrollPosition = toggleButton.offsetTop - window.innerHeight * 0.3;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      } else {
        detailList.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
}

export function collapseContent() {
  const detailList = document.querySelector('[data-tour__program__detail__list]');
  if (detailList) {
    detailList.setAttribute('data-is-expanded', 'false');
  }
}
