export function setupNavigation() {
  const addEventListeners = () => {
    if (window.matchMedia("(min-width: 830px)").matches) {
      const menuItems = document.querySelectorAll(".menu-item-has-children");

      menuItems.forEach((item) => {
        item.addEventListener("click", (event) => {
          const subnav = item.querySelector(".menu__subnav");
          if (subnav) {
            subnav.classList.toggle("state-visible");
          }
        });
      });
    }
  };

  const removeEventListeners = () => {
    const menuItems = document.querySelectorAll(".menu-item-has-children");
    menuItems.forEach((item) => {
      const newItem = item.cloneNode(true);
      item.parentNode.replaceChild(newItem, item);
    });
  };

  addEventListeners();

  window.addEventListener("resize", () => {
    removeEventListeners();
    addEventListeners();
  });

  const body = document.querySelector("body"),
    menu = document.querySelector(".menu"),
    menuButton = document.querySelector(".header__menu-button");

  menuButton.addEventListener("click", () => {
    body.classList.toggle("state-menu-active");
    menu.classList.toggle("state-active");
    menuButton.classList.toggle("state-active");
  });
}
