export function setupAccordions() {
  const headers = document.querySelectorAll(".ui-accordion__header");
  headers.forEach(header => {
    header.addEventListener("click", function () {
      const content = header.nextElementSibling;
      const icon = header.querySelector(".ui-expandButton");
      content.classList.toggle("is-active");
      icon.classList.toggle("is-active");
    });
  });
}
