export function buildNavigationList() {
  const tourSections = document.querySelectorAll(
    'main.tour *[id^="tour-section-"]'
  );
  const navList = document.querySelector("nav.tour__navigation ol");
  if (!navList) return; // Ensure the navigation list exists before attempting to build it

  tourSections.forEach((element) => {
    if (element.children.length > 0) {
      const listItem = document.createElement("li");
      const anchor = document.createElement("a");
      anchor.href = `#${element.id}`;
      anchor.textContent = element.dataset.title; // Assume `dataset.title` is pre-populated
      listItem.appendChild(anchor);
      navList.appendChild(listItem);
    }
  });
}

export function displayScrollIndicator() {
  const nav = document.querySelector(".tour__navigation");
  if (!nav) return;
  const ol = nav.querySelector("ol");
  const scrollIndicator = nav.querySelector(
    ".tour__navigation__scroll-indicator"
  );

  function checkOverflow() {
    // Check if the ol element is overflowing
    if (ol.scrollWidth > nav.clientWidth) {
      scrollIndicator.style.opacity = "1";
    } else {
      scrollIndicator.style.opacity = "0";
    }
  }

  // Initial check
  checkOverflow();

  // Check on window resize
  window.addEventListener("resize", checkOverflow);

  // Optionally, check on scroll to toggle the icon visibility when the user scrolls the nav
  ol.addEventListener("scroll", function () {
    if (ol.scrollLeft + nav.clientWidth < ol.scrollWidth) {
      scrollIndicator.style.opacity = "1";
    } else {
      scrollIndicator.style.opacity = "0";
    }
  });
}

export function setupNavigationObserver() {
  const navItems = document.querySelectorAll("nav.tour__navigation li");
  const tourSections = document.querySelectorAll(
    'main.tour *[id^="tour-section-"]'
  );

  console.log(navItems); // Should log a NodeList of navigation list items
  console.log(tourSections); // Should log a NodeList of sections with IDs starting with "tour-section-"

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        console.log(entry.target.id, entry.isIntersecting); // Log which section and if it's intersecting
        if (entry.isIntersecting) {
          const id = entry.target.getAttribute("id");
          navItems.forEach((item) => {
            const link = item.querySelector("a");
            item.classList.toggle(
              "is-active",
              "#" + id === link.getAttribute("href")
            );
          });
        }
      });
    },
    { threshold: 0 }
  );

  tourSections.forEach((section) => {
    observer.observe(section);
    console.log(`Observing: ${section.id}`); // Debug: log which sections are being observed
  });
}
