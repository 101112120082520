// glideSetup.js
import Glide from "@glidejs/glide";

export function initializeHeroCarousel() {
  const heroCarousel = document.querySelector(".glide__hero");
  if (heroCarousel) {
    new Glide(".glide__hero", {
      type: "carousel",
      autoplay: 2500,
      animationDuration: 600,
      animationTimingFunc: "ease-in-out",
    }).mount();
  }
}

export function initializeHeroCarouselHome() {
  const heroCarousel = document.querySelector(".glide__hero__home");
  if (heroCarousel) {
    new Glide(".glide__hero__home", {
      type: "carousel",
      autoplay: 2500,
      animationDuration: 600,
      animationTimingFunc: "ease-in-out",
    }).mount();
  }
}

export function initializeGalleryOverlay() {
  const galleryItems = document.querySelectorAll(
    ".tour__media__gallery__item a"
  );
  const galleryOverlay = document.getElementById("galleryOverlay");
  const closeOverlayButton = document.getElementById("closeOverlay");
  let glideTourGallery;

  galleryItems.forEach((item) => {
    item.addEventListener("click", function (e) {
      e.preventDefault();
      const clickedIndex = parseInt(
        item.parentElement.getAttribute("data-current-slide")
      );
      if (glideTourGallery) {
        glideTourGallery.destroy();
      }
      galleryOverlay.style.display = "flex";
      createSlidesAndShowGallery(
        glideTourGallery,
        galleryItems,
        ".glide__media__gallery",
        clickedIndex
      );
    });
  });

  if (closeOverlayButton) {
    closeOverlayButton.addEventListener("click", function () {
      galleryOverlay.style.display = "none";
      if (glideTourGallery) {
        glideTourGallery.destroy();
      }
    });
  }

  document.addEventListener("keydown", function (event) {
    if (event.key === "Escape" && galleryOverlay.style.display !== "none") {
      galleryOverlay.style.display = "none";
    }
  });
}

function createSlidesAndShowGallery(glideInstance, items, selector, startAt) {
  const glideSlides = document.querySelector(`${selector} .glide__slides`);
  glideSlides.innerHTML = "";
  items.forEach((galleryItem) => {
    const image = galleryItem.querySelector("img");
    if (image) {
      const clone = image.cloneNode(true);
      const li = document.createElement("li");
      li.className = "glide__slide";
      li.appendChild(clone);
      glideSlides.appendChild(li);
    }
  });
  glideInstance = new Glide(selector, { type: "carousel", startAt }).mount();
}
