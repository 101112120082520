export function setupAgendaOption() {
  const options = document.querySelectorAll('nav h4[data-tour_agenda_option]');
  if (options.length > 0) {
    options.forEach(option => {
      option.addEventListener('click', function () {
        const selectedOption = this.getAttribute('data-tour_agenda_option');

        // Update active class for h4 elements
        options.forEach(opt => {
          if (opt === this) {
            opt.classList.add('is-active'); // Add active class to clicked h4
          } else {
            opt.classList.remove('is-active'); // Remove from others
          }
        });

        // Toggle display of corresponding divs
        toggleDisplay(selectedOption);
      });
    });

    // Function to show/hide divs based on the matching data attribute
    function toggleDisplay(selectedOption) {
      const containers = document.querySelectorAll('[data-tour_agenda_option_container]');
      containers.forEach(container => {
        if (container.getAttribute('data-tour_agenda_option_container') === selectedOption) {
          container.style.display = 'block'; // Show matching container
        } else {
          container.style.display = 'none'; // Hide others
        }
      });
    }

    // Initialize by showing the first option and marking it as active
    if (options.length > 0) {
      options[0].classList.add('is-active');
      toggleDisplay('option-0'); // Optionally, set the initial active option here
    }
  }
}