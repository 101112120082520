import setupInteractionTrigger from "./modules/muiManager";
import {
  initializeHeroCarousel,
  initializeHeroCarouselHome,
  initializeGalleryOverlay,
} from "./modules/glideSetup";
import { setupAccordions } from "./modules/accordionSetup";
import { setupNavigation } from "./modules/navigationMenu";
import { setupAgendaOption } from "./modules/dayAgendaOptions";
import {
  setupNavigationObserver,
  buildNavigationList,
  displayScrollIndicator,
} from "./modules/navigationObserver";
import {
  setupDetailListToggle,
  collapseContent,
} from "./modules/collapseContent";

document.addEventListener("DOMContentLoaded", () => {
  setupAccordions();
  setupNavigation();
  buildNavigationList();
  displayScrollIndicator();
  setupDetailListToggle();
  setupAgendaOption();
});

window.onload = function () {
  setupNavigationObserver();
};

// Creating an array of functions to be executed on user interaction
const initializationFunctions = [
  initializeHeroCarousel,
  initializeHeroCarouselHome,
  initializeGalleryOverlay,
  collapseContent,
];

setupInteractionTrigger(initializationFunctions);
