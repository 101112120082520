const setupInteractionTrigger = (callback) => {
  let isCodeExecuted = false;  // Ensure code only runs once

  const handleFirstInteraction = () => {
    if (!isCodeExecuted) {
      if (typeof callback === 'function') {
        callback();
      } else if (Array.isArray(callback)) {
        callback.forEach(func => {
          if (typeof func === 'function') func();
        });
      }

      isCodeExecuted = true;  // Mark as executed
      console.log("Callback functions have been executed after user interaction.");
    }
  };

  // List of events that could denote user interaction
  const events = ["focus", "mousemove", "scroll", "touchstart"];
  events.forEach(event => {
    document.addEventListener(event, handleFirstInteraction, { once: true });
  });
};

export default setupInteractionTrigger;

